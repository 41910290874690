<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Merchant</label>
          <v-select
            v-model="merchantFilter"
            :options="merchantOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>APP</label>
          <v-select
            v-model="appFilter"
            :options="appOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Method</label>
          <v-select
            key="type_name"
            v-model="methodFilter"
            :options="methodOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            v-model="statusFilter"
            :options="statusOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date Mode</label>
          <v-select
            v-model="dateModeFilter"
            :options="dateModeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Timezone</label>
          <v-select
            v-model="tzFilter"
            :options="tzOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date Range</label>
          <date-picker
            v-model="dateRange"
            value-type="format"
            format="YYYY-MM-DD HH:mm:ss"
            type="datetime"
            range
            confirm
            :clearable="false"
            :shortcuts="shortcuts"
            :show-time-panel="showTimeRangePanel"
            @close="handleRangeClose"
          >
            <template v-slot:footer>
              <b-button variant="flat-primary" @click="toggleTimeRangePanel">
                {{ showTimeRangePanel ? 'select date' : 'select time' }}
              </b-button>
            </template>
          </date-picker>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Search Mode</label>
          <v-select
            v-model="searchModeFilter"
            :options="searchModeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:searchModeFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Search Key</label>
          <b-form-input
            id="searchKeyInput"
            v-model="searchKey"
            placeholder="Key Word Here..."
            @keydown.enter.native="doSearch"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label />
          <b-button
            class="mt-2"
            variant="primary"
            @click="doSearch"
          >
            Search
          </b-button>
          <b-dropdown split text="Download" variant="primary" class="mt-2 ml-2" right @click="doDownload(1)">
            <b-dropdown-item @click="doDownload(1)">
              Download
            </b-dropdown-item>
            <b-dropdown-item @click="doDownload(2)">
              Download Risk
            </b-dropdown-item>
            <b-dropdown-item @click="doDownload(3)">
              Download No Initials
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import { showAlert } from '@/libs/tool'
import useTransacitonsJs from './transactions'

const {
  fetchMerchants,
  fetchMerchantApps,
  fetchPayChannelListDetail,
} = useTransacitonsJs()

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
  },
  props: {
  },
  data() {
    return {
      params: {
        merchant_no: '',
        app_id: '',
        method: '',
        trade_status: '',
        start_time: '',
        end_time: '',
        channel: '',
      },

      payChannelList: [],

      merchantFilter: '',
      merchantOptions: [],

      providerFilter: [''],
      providerOptions: [],

      appFilter: '',
      appOptions: [],

      regionFilter: '',
      regionOptions: [],

      methodFilter: '',
      methodOptions: [
        { label: 'All', value: '' },
        { label: 'PIX', value: 'PIX' },
        { label: 'Boleto', value: 'Boleto' },
      ],

      channelFilter: '',
      channelOptions: [{ label: 'All', value: '' }],

      statusFilter: '',
      statusOptions: [
        { label: 'All', value: '' },
        { label: 'Initial', value: '10001' },
        { label: 'Authorized', value: '17000' },
        { label: 'Processing', value: '18000' },
        { label: 'RiskControl', value: '27000' },
        { label: 'Cancelled', value: '25000' },
        { label: 'CancelledByMerchant', value: '25001' },
        { label: 'Success', value: '20000' },
        { label: 'Dispute', value: '30000' },
        { label: 'Refused', value: '21000' },
        { label: 'OpenRefund', value: '22005' },
        { label: 'RefundProcessing', value: '22006' },
        { label: 'Refunded', value: '22000' },
        { label: 'PartialRefund', value: '22001' },
        { label: 'ChargeBack', value: '23000' },
        { label: 'Fail', value: '40000' },
        { label: 'Expired', value: '26000' },
      ],

      dateModeFilter: '1',
      dateModeOptions: [
        { label: 'Create Time', value: '1' },
        { label: 'Update Time', value: '2' },
      ],

      searchModeFilter: '1',
      searchModeOptions: [
        { label: 'Transaction ID', value: '1' },
        { label: 'Merchant Reference', value: '2' },
        { label: 'Partner ID', value: '3' },
        { label: 'Merchant UID', value: '4' },
        { label: 'Email', value: '5' },
        { label: 'User Identify', value: '6' },
        // { label: 'Barcode', value: '7' },
        { label: 'Barcode', value: '8' },
        { label: 'Clabe', value: '9' },
        { label: 'Partner Status', value: '10' },
        { label: 'Card Number', value: '11' },
        { label: 'Luxpag Merchant Reference', value: '12' },
      ],

      tzFilter: 'Etc/GMT+3',
      tzOptions: [
        // { label: 'Etc/GMT-12', value: 'Etc/GMT-12' },
        // { label: 'Etc/GMT-11', value: 'Etc/GMT-11' },
        // { label: 'Etc/GMT-10', value: 'Etc/GMT-10' },
        // { label: 'Etc/GMT-9', value: 'Etc/GMT-9' },
        { label: 'Asia/Shanghai', value: 'Etc/GMT-8' },
        // { label: 'Etc/GMT-7', value: 'Etc/GMT-7' },
        // { label: 'Etc/GMT-6', value: 'Etc/GMT-6' },
        // { label: 'Etc/GMT-5', value: 'Etc/GMT-5' },
        // { label: 'Etc/GMT-4', value: 'Etc/GMT-4' },
        // { label: 'Etc/GMT-3', value: 'Etc/GMT-3' },
        // { label: 'Etc/GMT-2', value: 'Etc/GMT-2' },
        // { label: 'Etc/GMT-1', value: 'Etc/GMT-1' },
        { label: 'Etc/GMT', value: 'Etc/GMT' },
        // { label: 'Etc/GMT+1', value: 'Etc/GMT+1' },
        // { label: 'Etc/GMT+2', value: 'Etc/GMT+2' },
        { label: 'America/Sao_Paulo', value: 'Etc/GMT+3' },
        // { label: 'Etc/GMT+4', value: 'Etc/GMT+4' },
        // { label: 'Etc/GMT+5', value: 'Etc/GMT+5' },
        { label: 'America/Mexico_City', value: 'Etc/GMT+6' },
        // { label: 'Etc/GMT+7', value: 'Etc/GMT+7' },
        // { label: 'Etc/GMT+8', value: 'Etc/GMT+8' },
        // { label: 'Etc/GMT+9', value: 'Etc/GMT+9' },
        // { label: 'Etc/GMT+10', value: 'Etc/GMT+10' },
        // { label: 'Etc/GMT+11', value: 'Etc/GMT+11' },
        // { label: 'Etc/GMT+12', value: 'Etc/GMT+12' },
      ],

      searchKey: '',
      showTimeRangePanel: false,
      dateRange: [],
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const end = moment().subtract(1, 'days').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 7 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(6, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'This Month',
          onClick() {
            const end = moment().endOf('month').endOf('day')
              .toDate()
            const start = moment().startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last Month',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 3 Months',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(3, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
      ],
    }
  },
  computed: {
    channelDisabled() {
      return this.methodFilter === 'All' || this.methodFilter.type_id === ''
    },
  },
  watch: {
    dateRange: function formatWithTz(object) {
      const startTime = object[0]
      const endTime = object[1]
      const startTzTime = moment.tz(startTime, this.tzFilter)
      const endTzTime = moment.tz(endTime, this.tzFilter)
      const startTimeStr = startTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
      const endTimeStr = endTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')

      const dateArray = new Array(0)
      dateArray.push(startTimeStr, endTimeStr)
      this.$emit('update:dateRange', dateArray)
    },
    merchantFilter: function fillApps(merchantNo) {
      this.appOptions = []
      this.appFilter = ''
      if (merchantNo === 'All') {
        this.appOptions = [{ label: 'All', value: 'All' }]
        this.appFilter = this.appOptions[0].value
      } else {
        fetchMerchantApps(this, merchantNo, apps => {
          this.appOptions = apps
          this.appFilter = this.appOptions[0].value
        })
      }
    },
  },
  created() {
    fetchMerchants(this, merchants => {
      this.merchantOptions = merchants
      this.merchantFilter = this.merchantOptions[0].value
    })
    fetchPayChannelListDetail(this, list => {
      this.payChannelList = list
    })

    this.dateRange = [moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')]
  },
  methods: {
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel
    },
    handleRangeClose() {
      this.showTimeRangePanel = false
    },
    genParams() {
      let [start, end] = this.dateRange

      start = moment.tz(moment.tz(start, this.tzFilter), 'UTC').format('YYYY-MM-DD HH:mm:ss')
      end = moment.tz(moment.tz(end, this.tzFilter), 'UTC').format('YYYY-MM-DD HH:mm:ss')

      return {
        merchant_no: this.merchantFilter === 'All' ? '' : this.merchantFilter,
        app_id: this.appFilter === 'All' ? '' : this.appFilter,
        method: this.methodFilter,
        trade_status: this.statusFilter,
        status: this.statusFilter,
        channels: ['1003111110010001', '1025111110010001', '1025113710010001'],
        start_time: start,
        end_time: end,

        date_mode: this.dateModeFilter,
        search_mode: this.searchModeFilter,
        search_key: this.searchKey,
        page_num: 1,
        zone_id: this.tzFilter,
      }
    },
    doSearch() {
      const params = this.genParams()

      if (params.search_mode === '11' && params.method === 'All') {
        showAlert(this, 'warning', 'Warning', 'specific methods are required')
        return
      }

      this.$emit('search', params)
    },
    doDownload(dataFilter) {
      const params = this.genParams()
      params.data_filter = dataFilter
      this.$emit('download', params)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
</style>
