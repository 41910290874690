<template>

  <div>
    <!-- Filters -->
    <transactions-list-filters
      @search="search"
      @download="download"
    />

    <!-- Table Container Card -->
    <b-card no-body>

      <div class="m-1">
        <b-row>
          <div class="ml-2">
            <b-button
              variant="outline-primary"
              @click="toggleTableStacked"
            >
              <span class="text-nowrap">Toggle Table View Mode</span>
            </b-button>
          </div>
          <div class="ml-2">
            <b-button
              variant="outline-primary"
              @click="columnFilterShow = true"
            >
              <span class="text-nowrap">Filter Column Visibility</span>
            </b-button>
          </div>
          <div class="ml-2">
            <b-button
              variant="outline-primary"
              @click="stickyHeader = stickyHeader ? false : '600px'"
            >
              <span class="text-nowrap">Toggle Sticky Header</span>
            </b-button>
          </div>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="transactionsTableFields"
        :items="transactions"
        primary-key="trade_no"
        show-empty
        striped
        :sticky-header="stickyHeader"
        :stacked="stacked"
        :borderless="false"
        min-row="10"
        empty-text="No matching records found"
      >

        <template #cell(trade_no)="data">
          <b-link
            :to="{ name: 'transactions-detail', query:{trade_no: data.item.trade_no, app_id: data.item.app_id , out_trade_no: data.item.out_trade_no , zone_id: tzSelected} }"
            target="_blank"
            class="font-weight-bold d-block"
          >
            {{ data.item.trade_no }}
          </b-link>
        </template>

        <template #cell(merchant)="data">
          {{ data.item.merchant_name }}
          <small class="text-muted">@{{ data.item.merchant_no }}</small>
        </template>

        <template #cell(app)="data">
          {{ data.item.app_name }}
          <small class="text-muted">@{{ data.item.app_id }}</small>
        </template>

        <template #cell(order_amount)="data">
          {{ data.item.order_currency }} {{ data.item.order_amount }}
        </template>

        <template #cell(pay_amount)="data">
          {{ data.item.pay_currency }} {{ data.item.pay_amount }}
        </template>

        <template #cell(channel)="data">
          {{ data.item.channel_name }}
          <small v-if="data.item.channel_id && data.item.channel_id != ''" class="text-muted">@{{ data.item.channel_id }}</small>
        </template>

        <template #cell(create_time)="data">
          {{ dateFormat(data.item.create_time) }}
        </template>

        <template #cell(update_time)="data">
          {{ dateFormat(data.item.update_time) }}
        </template>

        <template #cell(trade_status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.trade_status)}`"
          >
            {{ data.item.trade_status }}
          </b-badge>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      v-model="columnFilterShow"
      title="Column Filter"
      ok-title="Accept"
      size="lg"
      centered
      @ok="filterColumnVisibility"
    >
      <!-- <b-form-group>
        <b-form-checkbox-group
          v-model="columnSeleted"
          :options="tableFieldsTemplate"
          switches
          text-field="label"
          value-field="key"
          class="demo-inline-spacing"
        />
      </b-form-group> -->
      <draggable
        :list="tableFieldsTemplate"
        tag="ul"
        group="people"
        class="list-group list-group-flush cursor-move"
      >
        <b-list-group-item
          v-for="(field, index) in tableFieldsTemplate"
          :key="index"
          tag="li"
        >
          <b-row>
            <b-col md="6">
              {{ field.label }}
            </b-col>
            <b-col md="3">
              <b-form-checkbox v-model="field.isShow" class="ml-2">
                Show?
              </b-form-checkbox>
            </b-col>
            <b-col md="3">
              <b-form-checkbox v-model="field.stickyColumn" class="ml-2">
                Sticky?
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-list-group-item>
      </draggable>
    </b-modal>

    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BBadge, BCard, BRow, BCol, BLink, BTable, BPagination, BOverlay, BButton, VBModal, BListGroupItem, BFormCheckbox,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import router from '@/router'
import moment from 'moment-timezone'
import { showToast } from '@/libs/tool'
import TransactionsListFilters from './TransactionsListFilters.vue'
import useTransacitonsJs from './transactions'

const {
  searchTransactionsByFilter,
  downloadTransactionsByFilter,
} = useTransacitonsJs()

export default {
  components: {
    TransactionsListFilters,
    draggable,

    BBadge,
    BCard,
    BRow,
    BCol,
    BLink,
    BTable,
    BPagination,
    BOverlay,
    BButton,
    BListGroupItem,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      loading: false,
      searchRequest: {},
      transactions: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      tableFieldsTemplate: [
        {
          key: 'trade_no',
          label: 'TRANSACTION ID',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'out_trade_no',
          label: 'MERCHANT REFERENCE',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'merchant',
          label: 'MERCHANT',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'app',
          label: 'APP',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'create_time',
          label: 'ORDER TIME',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'order_amount',
          label: 'ORDER AMOUNT',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'pay_amount',
          label: 'PAY AMOUNT',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'method',
          label: 'METHOD',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'channel',
          label: 'CHANNEL',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'trade_status',
          label: 'STATUS',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'channel_order_id',
          label: 'PARTNER ID',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'channel_order_status',
          label: 'PARTNER STATUS',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'user_identify',
          label: 'User Identification',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'email',
          label: 'EMAIL',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'out_uid',
          label: 'MERCHANT UID',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'update_time',
          label: 'UPDATE TIME',
          isShow: true,
          stickyColumn: false,
        },
      ],
      transactionsTableFields: [
        { key: 'trade_no', label: 'TRANSACTION ID' },
        { key: 'out_trade_no', label: 'MERCHANT REFERENCE' },
        { key: 'merchant', label: 'MERCHANT' },
        { key: 'app', label: 'APP' },
        { key: 'create_time', label: 'ORDER TIME' },
        { key: 'order_amount', label: 'ORDER AMOUNT' },
        { key: 'pay_amount', label: 'PAY AMOUNT' },
        { key: 'method', label: 'METHOD' },
        { key: 'channel', label: 'CHANNEL' },
        { key: 'trade_status', label: 'STATUS' },
        { key: 'channel_order_id', label: 'PARTNER ID' },
        { key: 'channel_order_status', label: 'PARTNER STATUS' },
        { key: 'user_identify', label: 'USER IDENTIFICATION' },
        { key: 'email', label: 'EMAIL' },
        { key: 'out_uid', label: 'MERCHANT UID' },
        { key: 'update_time', label: 'UPDATE TIME' },
      ],
      currentPage: 1,
      totalTransactions: 0,
      tzSelected: 'Etc/GMT+3',
      stacked: false,
      columnFilterShow: false,
      stickyHeader: false,
      columnSeleted: [
        'trade_no',
        'out_trade_no',
        'merchant',
        'app',
        'create_time',
        'order_amount',
        'pay_amount',
        'method',
        'channel',
        'trade_status',
        'channel_order_id',
        'channel_order_status',
        'user_identify',
        'email',
        'out_uid',
        'update_time',
      ],
    }
  },
  watch: {
    currentPage: function refetchSearchData(pageNum) {
      this.searchRequest.page_num = pageNum
      this.search(this.searchRequest)
    },
  },
  created() {
    const tableFields = localStorage.getItem('TransactionTableFields')
    if (tableFields) {
      this.transactionsTableFields = JSON.parse(tableFields)

      const newTemplate = []
      this.tableFieldsTemplate.forEach(field => {
        const newField = field
        let hasColumn = false
        this.transactionsTableFields.forEach(tab => {
          if (tab.key === newField.key) {
            hasColumn = true
            newField.isShow = tab.isShow
            newField.stickyColumn = tab.stickyColumn
          }
        })

        if (!hasColumn) {
          newField.isShow = false
          newField.stickyColumn = false
        }

        newTemplate.push(newField)
      })

      this.tableFieldsTemplate = newTemplate
    }
  },
  methods: {
    resolveStatusVariant(status) {
      switch (status) {
        case 'Processing':
          return 'primary'
        case 'Success':
          return 'success'
        case 'ChargeBack':
          return 'danger'
        case 'Refunded':
        case 'PartialRefund':
          return 'danger'
        case 'RiskControl':
        case 'Dispute':
        case 'Pending':
          return 'warning'
        case 'Refused':
          return 'info'
        default:
          return 'secondary'
      }
    },
    dateFormat(value) {
      if (!value) {
        return ''
      }
      return moment.tz(moment.tz(value, 'UTC'), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
    search(params) {
      this.loading = true
      this.searchRequest = params
      this.tzSelected = decodeURIComponent(params.zone_id)
      searchTransactionsByFilter(
        this.searchRequest,
        transactions => {
          this.transactions = transactions.list
          this.totalTransactions = transactions.total
          this.dataMeta.from = this.currentPage * 10 - (10 - 1)
          this.dataMeta.to = this.totalTransactions - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalTransactions
          this.dataMeta.of = this.totalTransactions

          this.loading = false
        },
        fail => {
          showToast(this, 'Warning', `Fetch transactions fail with ${fail}`)
        },
      )
    },
    download(params) {
      this.searchRequest = params
      downloadTransactionsByFilter(
        this.searchRequest,
        () => {
          showToast(this, 'Success', 'Download Transactions Request Submitted')

          setTimeout(() => {
            router.push({
              name: 'transactions-download-log',
            })
          }, 500)
        },
        fail => {
          showToast(this, 'Warning', `Download Transactions Failure with ${fail}`)
        },
      )
    },
    toggleTableStacked() {
      this.stacked = !this.stacked
    },
    filterColumnVisibility() {
      const tableFields = []
      this.tableFieldsTemplate.forEach(field => {
        if (field.isShow === true) {
          tableFields.push(field)
        }
      })
      this.transactionsTableFields = tableFields
      localStorage.setItem('TransactionTableFields', JSON.stringify(this.transactionsTableFields))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
